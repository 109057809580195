/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import AOS from 'aos';
import Masonry from 'masonry-layout';

// Calculate
var timeout = false,
	timeoutDelay = 250;

function calculateSizes() {
	document.documentElement.style.setProperty('--vh', `${document.documentElement.clientHeight}px`);
	document.documentElement.style.setProperty('--sw', `${window.innerWidth - document.documentElement.clientWidth}px`);
}

calculateSizes();

window.addEventListener('resize', function () {
	clearTimeout(timeout);
	timeout = setTimeout(calculateSizes, timeoutDelay);
});

// Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine',
	duration: 300
});

// Lightgallery
async function loadLightGallery() {
	await import(/* webpackChunkName: "lightgallery" */ 'lightgallery.js');
	await import(/* webpackChunkName: "lightgallery" */ 'lg-video.js');

	lightGallery(document.getElementsByTagName("body")[0], {
		selector: '[rel=modal]',
		download: false,
		thumbnail: false,
		fullScreen: true,
		actualSize: false,
		hash: false,
		videoMaxWidth: '1920px'
	});
}

var elementsWithModal = document.querySelectorAll('[rel=modal]');
if (elementsWithModal.length) {
	loadLightGallery();
}

// Masonry
var elem = document.querySelectorAll('.masonry-columns').forEach(el => {
	var msnry = new Masonry(el, {
		// options
		itemSelector: '.masonry-column',
		percentPosition: true
	});
});

// USP slider
var uspSlider = document.querySelectorAll('.usp-block');
if (uspSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		uspSlider.forEach(el => {
			const contentSlider = el.querySelector('.usp-block__slider'),
				columnBlock = el.querySelector('.usp-block__slider .columns'),
				swiperItems = columnBlock.querySelectorAll('.usp-block__slider .column'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			swiperPagination.setAttribute('class', 'swiper-pagination');
			contentSlider.appendChild(swiperPagination);
			contentSlider.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 0,
				pagination: {
					el: ".swiper-pagination",
					type: "progressbar",
				},
				breakpoints: {
					640: {
						slidesPerView: 2
					},
					1024: {
						slidesPerView: 3
					},
					1280: {
						slidesPerView: 4
					}
				}
			});
		});
	})();
}

// Testimonial slider
var testimonialSlider = document.querySelectorAll('.testimonial-block');
if (testimonialSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		testimonialSlider.forEach(el => {
			const contentSlider = el.querySelector('.testimonial__slider'),
				columnBlock = el.querySelector('.testimonial__slider .columns'),
				swiperItems = columnBlock.querySelectorAll('.testimonial__slider .column'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			swiperPagination.setAttribute('class', 'swiper-pagination');
			contentSlider.appendChild(swiperPagination);
			contentSlider.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 48,
				pagination: {
					el: ".swiper-pagination",
					type: "progressbar",
				},
				breakpoints: {
					640: {
						slidesPerView: 2
					},
					1280: {
						slidesPerView: 3
					}
				}
			});
		});
	})();
}

// Logo slider
var logoSliders = document.querySelectorAll('.logo-slider-block');
if (logoSliders.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		logoSliders.forEach(el => {
			const contentSlider = el.querySelector('.logo-slider-block__slider'),
				columnBlock = el.querySelector('.logo-slider-block__slider .columns'),
				swiperItems = columnBlock.querySelectorAll('.logo-slider-block__slider .column'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			swiperPagination.setAttribute('class', 'swiper-pagination');
			contentSlider.appendChild(swiperPagination);
			contentSlider.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 'auto',
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 48,
				pagination: {
					el: ".swiper-pagination",
					type: "progressbar",
				}
			});
		});
	})();
}

// Combideal slider
var combidealSlider = document.querySelectorAll('.combideal-slider');
if (combidealSlider.length) {
	(async () => {
		const Swiper = (await import(/* webpackMode: "eager" */ './modules/swiper.js')).default;

		combidealSlider.forEach(el => {
			const contentSlider = el.querySelector('.content-block__slider'),
				columnBlock = el.querySelector('.content-block__slider .columns'),
				swiperItems = columnBlock.querySelectorAll('.content-block__slider .column'),
				swiperContainer = document.createElement('div'),
				swiperPagination = document.createElement('div');

			swiperContainer.setAttribute('class', 'swiper-container');
			columnBlock.setAttribute('class', 'swiper-wrapper');
			columnBlock.parentNode.insertBefore(swiperContainer, columnBlock);
			swiperContainer.appendChild(columnBlock);
			swiperPagination.setAttribute('class', 'swiper-combideal-pagination');
			contentSlider.appendChild(swiperPagination);
			contentSlider.classList.add('has-swiper');

			swiperItems.forEach(si => {
				si.classList.remove('column');
				si.classList.add('swiper-slide');
			});

			new Swiper(el.querySelector('.swiper-container'), {
				slidesPerView: 1,
				allowTouchMove: true,
				autoplay: {
					delay: 5000,
					duration: 2000
				},
				keyboard: {
					enabled: true
				},
				spaceBetween: 48,
				pagination: {
					el: swiperPagination,
					type: "progressbar",
				}
			});
		});
	})();
}

//Video figure handling
document.querySelectorAll('.video-figure').forEach(function (videoFigure) {
	var hovered = false;
	videoFigure.addEventListener("mouseenter", function (e) {
		if (!hovered) {
			videoFigure.querySelector("video").classList.remove("hide-element");
			var figcaption = videoFigure.querySelector("figcaption");
			if (figcaption) {
				figcaption.classList.add("hide-element");
			}
			videoFigure.querySelector("video").play();
			hovered = true;
		}
	});

	videoFigure.addEventListener("mouseleave", function (e) {
		if (hovered) {
			videoFigure.querySelector("video").classList.add("hide-element");
			var figcaption = videoFigure.querySelector("figcaption");
			if (figcaption) {
				figcaption.classList.remove("hide-element");
			}
			videoFigure.querySelector("video").pause();
			hovered = false;
		}
	});
});

// Close dropdowns on escape key
var dropdowns = document.querySelectorAll('.dropdown-checkbox').forEach(function (dropdown) {
	if (dropdown) {

		const toggle = checked => {
			dropdown.checked = checked;
		};

		document.addEventListener('keydown', e => {
			if (e.keyCode === 27 && dropdown.checked) {
				toggle(false);
			}
		});

		document.addEventListener('click', e => {
			if (dropdown.checked && e.target !== dropdown && !(e.target.classList.contains("dropdown-label"))) {
				console.log(e.target);
				toggle(false);
			}
		});

		// Keep aria-expanded in-sync with checked state
		dropdown.addEventListener('change', () => {
			toggle(dropdown.checked);
		});

		toggle(dropdown.checked);
	}
});

// Close menu on escape key
const navigationToggler = document.getElementById('nav');
if (navigationToggler) {

	const toggle = checked => {
		navigationToggler.checked = checked;
	};

	document.addEventListener('keydown', e => {
		if (e.keyCode === 27 && navigationToggler.checked) {
			toggle(false);
		}
	});

	document.addEventListener('click', e => {
		if (navigationToggler.checked === true) {
			if (e.target.classList.contains("nav-backdrop")) {
				toggle(false);
			}
		}
	});

	// Keep aria-expanded in-sync with checked state
	navigationToggler.addEventListener('change', () => {
		toggle(navigationToggler.checked);
	});

	toggle(navigationToggler.checked);
}

// Jump to submitted form
document.querySelectorAll('.form-block, .arrangement-block, .contact-block').forEach(el => {
	var form = el.querySelector('form'),
		button = el.querySelector('.button');

	if (form) {

		form.addEventListener('submit', function () {
			button.classList.add('is-loading');
		});

		var anchor = el.querySelector('.form').getAttribute('id');
		form.setAttribute('action', form.getAttribute('action') + '#' + anchor);
	}
});